import React from 'react'
import { graphql, Link } from 'gatsby'
import { Layout } from '../components'
import { Helmet } from 'react-helmet'

const TutorialLink = ({ tutorial: { path, title, category, date } }) => (
  <li>
    <Link to={path} style={{ fontSize: '1.25em' }}>
      {date} [{title}]
    </Link>
    <span style={{ fontSize: '.75rem' }}>
      {' '}
      / {category ? category.toLowerCase() : ''}
    </span>
  </li>
)

export default ({
  data: {
    site: {
      siteMetadata: { tutorials, title },
    },
  },
}) => {
  return (
    <Layout>
      <Helmet>
        <title>{title} | tutorials</title>
        <meta name="twitter:title" content={`${title} | tutorials`} />
      </Helmet>
      <div
        style={{
          padding: '1.45rem 1.0875rem',
          width: '100%',
          maxWidth: 960,
          margin: '0 auto',
        }}
      >
        <h1>Tutorials</h1>
        <ul style={{ listStyleType: 'none', margin: 0, padding: 0 }}>
          {tutorials.map(tutorial => (
            <TutorialLink key={tutorial.title} tutorial={tutorial} />
          ))}
        </ul>
      </div>
    </Layout>
  )
}

export const pageQuery = graphql`
  query TutorialsQuery {
    site {
      siteMetadata {
        title
        tutorials {
          title
          category
          path
          date(formatString: "MM.DD.YYYY")
        }
      }
    }
  }
`
